import React, { useEffect, useState } from 'react';
import {
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardText,
	MDBCardImage,
	MDBBtn,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBIcon
} from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addToCart } from '../app/CartSlice';
import Carousel from './Carousel';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

function ProductCart() {
	const [items, setItems] = useState([])
	const [staticItemsState, setStaticItemsState] = useState([])
	const [categoryNames, setCategoryNames] = useState('All Products')
	const itemss = useSelector(state => state.allCart.items);
	const staticItems = useSelector(state => state.allCart.items);//all static array
	const itemId = useSelector(state => state.allCart.itemId);
	const dispatch = useDispatch();
	let uniqueCategory = [...new Set(staticItems.map(item => item.category))];
	// console.log(uniqueCategory)
	uniqueCategory = ['All Products',...uniqueCategory];

	useEffect(() => {
		setItems(itemss)
		setStaticItemsState(staticItems)
	}, [])
	function categoryFilter(e) {
		let categoryName = e.target.value;
		// console.log(categoryName)
		if(categoryName=='All Products'){
			// console.log(staticItemsState)
			setCategoryNames(categoryName)
			setItems(staticItemsState);return;
		}
		let categoryFilterArray = itemss.filter((item) => {//for filter use main array copy
			// console.log(item.category)
			return (item.category == categoryName)
		}
		)
		// console.log(categoryFilterArray)
		setItems(categoryFilterArray)
		setCategoryNames(categoryName)
	}
	// console.log(items);return;
	return (
		<div>
			<br /><br />
			<h3 className='text-center'>{categoryNames}</h3><br />
			<MDBContainer>
				<MDBRow className='row'>
					<div className='col-md-2' style={{borderRight:'1px solid lightgray'}}>
						<>
							{

								uniqueCategory.map((item,index) => {
									return (
									<div class="list-group" key={index}>
									<button class="list-group-item list-group-item-action" value={item} onClick={categoryFilter}>{item}</button>
									{/* <a href="#" class="list-group-item list-group-item-action disabled">Vestibulum at eros</a> */}
								  </div>
									)
								})
							}&nbsp;
						</>
					</div>
					<MDBRow className='col-md-10'>
						{items.map((item, index) => {
							const result = itemId.includes(item.id);
							// console.log(result);
							let res = (result) ? 'disabled' : '';
							let btnClass = (result) ? 'btn btn-success btn-md' : 'btn btn-primary btn-md';
							let btnName = (result) ? 'Item Added to Cart' : 'Add to Cart';
							return <div className='col-md-3 col-sm-3 col-xs-6' key={index}>
								<MDBCard alignment='center' style={{ marginBottom: '20px' }}>
									<Link to={`/ProductDetails/${item.id}`}>
										<div className='text-center'>
											<MDBCardImage className='img-thumbnail' src={item.image} position='top' alt={item.title} style={{ width: "200px", height: "200px" }} />
										</div>
									</Link>
									<MDBCardBody>
										<Link to={`/ProductDetails/${item.id}`}><MDBCardTitle style={{ fontSize: '16px', minHeight: '35px' }}>{item.title}</MDBCardTitle></Link><br />
										<MDBCardText>MRP &nbsp;
											<span className='fa fa-inr'>&nbsp;</span>{item.price}
										</MDBCardText>
										<button className={btnClass} disabled={res} onClick={() => dispatch(addToCart(item))}><i className="fas fa-shopping-cart">&nbsp;</i>{btnName}</button>
									</MDBCardBody>
								</MDBCard>
							</div>
						})}
					</MDBRow>
				</MDBRow>
			</MDBContainer>
		</div>
	);
}
export default ProductCart;