import React from 'react'

function About() {
	return (
		<div className='container'><br/>
			<div className='row'>
				<h1 className='text-center'>About Us</h1><br/>
				<h3 className='text-center'>Welcome to our world of E-Mart!</h3><br/>

<p>At Emart, we take pride in offering a wide range of cutting-edge electronics and high-quality Fast-Moving Consumer Goods (FMCG) to enrich your life and cater to your everyday needs. Our mission is to provide you with innovative products that enhance your lifestyle, and we are committed to delivering exceptional customer experiences.<br/>

About Us:

Who we are:
E-Mart is a leading global provider of electronics and FMCG products, established with a passion for innovation and a commitment to excellence. Our team of experts is dedicated to curating an extensive selection of products that meet the ever-evolving needs of our customers.
<br/>
Our Vision:
Our vision is to become the preferred destination for customers seeking the latest electronics and FMCG products. We aim to be recognized for our reliability, outstanding service, and the continuous pursuit of technological advancements.
<br/>
Our Mission:
At E-Mart, we are on a mission to empower our customers with the latest and most reliable products in the market. By offering a diverse portfolio of electronics and FMCG items, we strive to enhance convenience, entertainment, and productivity in your daily life.
<br/><br/>
What sets us apart:
1. Extensive Product Range: We take pride in our diverse selection of electronics and FMCG products, ensuring you find everything you need in one place.
<br/><br/>
2. Quality Assurance: We are committed to offering only the highest quality products, sourced from reputable manufacturers and trusted brands.
<br/><br/>
3. Innovation: Our team is dedicated to staying ahead of the curve and offering the latest technological advancements to our customers.
<br/><br/>
4. Customer-centric Approach: Your satisfaction is our top priority. We are here to assist you at every step, providing excellent customer support and after-sales service.
<br/><br/>
5. Competitive Prices: We believe in offering the best value for your money, with competitive pricing on all our products.
<br/><br/>
Product Categories:<br/><br/>

Electronics:
From smartphones and laptops to smart home devices and wearables, our electronics category offers a comprehensive selection of the latest gadgets to keep you connected, entertained, and productive.
<br/><br/>
FMCG Products:
In our FMCG category, you'll discover a wide array of everyday essentials and popular products, including personal care items, household goods, beverages, snacks, and much more.
<br/><br/>
Our Commitment to Sustainability:
We understand the importance of preserving our planet for future generations. That's why we strive to work with eco-friendly brands, promote sustainable practices, and reduce our environmental footprint in every way possible.
<br/><br/>
Join us on our journey:
We invite you to join us on this exciting journey of exploration and discovery. Browse our website or visit our stores to experience the world of cutting-edge electronics and premium FMCG products. Stay connected with us for the latest updates, exclusive offers, and much more!
<br/><br/>
Thank you for choosing E-Mart. We look forward to serving you and exceeding your expectations at every turn. If you have any questions or feedback, please don't hesitate to contact our friendly customer support team.
<br/><br/>
Welcome to a world of possibilities and convenience!
<br/><br/>
E-Mart Team</p>
			</div>
		</div>
	)
}

export default About