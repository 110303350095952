import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
	return (
		<>
			<section className="">
				<footer className="bg-primary text-white text-center text-md-start">
					<div className="container p-4">
						<div className="row">
							<div className="col-lg-6 col-md-12 mb-4 mb-md-0">
								<h5 className="text-uppercase">E-Mart</h5>
								<p>
								E-Mart is a leading global provider of electronics and FMCG products, established with a passion for innovation and a commitment to excellence. Our team of experts is dedicated to curating an extensive selection of products that meet the ever-evolving needs of our customers.
								</p>
							</div>

							<div className="col-lg-3 col-md-6 mb-4 mb-md-0">
								{/* <h5 className="text-uppercase">Links</h5>

								<ul className="list-unstyled mb-0">
									<li>
										<a href="#!" className="text-white">Link 1</a>
									</li>
									<li>
										<a href="#!" className="text-white">Link 2</a>
									</li>
									<li>
										<a href="#!" className="text-white">Link 3</a>
									</li>
									<li>
										<a href="#!" className="text-white">Link 4</a>
									</li>
								</ul> */}
							</div>

							<div className="col-lg-3 col-md-6 mb-4 mb-md-0">
								<h5 className="text-uppercase mb-0">Links</h5>

								<ul className="list-unstyled">
									<li>
										<Link to='/' className="text-white">Home</Link>
									</li>
									<li>
										<Link to='/about' className="text-white">About</Link>
									</li>
									<li>
										<Link to='/benefits' className="text-white">Benefits</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
						© 2023 Copyright:
						<a className="text-white" href="https://yogesh-bathe.xyz" target='_blank'>yogesh-bathe.xyz</a>
					</div>
				</footer>
			</section>
		</>
	)
}

export default Footer