import React, { useState, useEffect } from 'react';

import { getCartTotal } from '../app/CartSlice';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from './Carousel';


export default function Navbar() {
    const { cart, totalPrice } = useSelector(state => state.allCart)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCartTotal())
    }, [cart])
    const [showBasic, setShowBasic] = useState(false);

    return (
        <>
            <MDBNavbar expand='lg' light bgColor='light' style={{ position: 'fixed', width: '100%', zIndex: '999' }}>
                <MDBContainer fluid>
                    <MDBNavbarBrand><img src='/images/logo.jpg' width='40$' /></MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowBasic(!showBasic)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={showBasic}>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page'>
                                    <Link to='/'>Home</Link>
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink>
                                    <Link to='/about'>About</Link>
                                </MDBNavbarLink>
                            </MDBNavbarItem>

                            <MDBNavbarItem>
                                <MDBNavbarLink>
                                    <Link to='/benefits'>Online Benefits</Link>
                                </MDBNavbarLink>
                            </MDBNavbarItem>

                            {/* <MDBNavbarItem>
                                <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                                    Disabled
                                </MDBNavbarLink>
                            </MDBNavbarItem> */}
                        </MDBNavbarNav>
                        {/* <i className="fas fa-shopping-cart"><span className="badge badge-warning">{cart.length}</span></i>
                             */}
                        <Link to='/cart'><a>
                            <i className="fas fa-shopping-cart fa-lg"></i>
                            <span className="badge rounded-pill badge-notification bg-danger">{cart.length}</span>
                            <span className="fas fa-inr fa-sm"> &nbsp;{totalPrice}</span>
                        </a></Link>
                        {/* <form className='d-flex input-group w-auto'>
            <input type='search' className='form-control' placeholder='Type query' aria-label='Search' />
            <MDBBtn color='primary'>Search</MDBBtn>
          </form> */}
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
            <Carousel />
        </>
    );
}