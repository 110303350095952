import { createSlice } from "@reduxjs/toolkit";
import ProductData from "../ProductData";
const initialState = {
    cart: [],
    items: ProductData,
    totalQuantity: 0,
    totalPrice: 0,
    itemId:[]
}
const cartSlice = createSlice({
    name: 'allCart',
    initialState: initialState,
    reducers: {
        addToCart: (state, action) => {
            console.log(state.itemId);
            let find = state.cart.findIndex((item)=>item.id==action.payload.id)
            if(find>=0){
                state.cart[find].quantity++
            }else{
                state.cart.push(action.payload)
                state.itemId.push(action.payload.id)
            }

        },
        getCartTotal: (state, action) => {
            let { totalQuantity, totalPrice } = state.cart.reduce(
                (cartTotal, cartItem) => {
                    // console.log(cartTotal)
                    // console.log(cartItem)
                    const { price, quantity } = cartItem;
                    const itemTotal = price * quantity;
                    cartTotal.totalPrice += itemTotal;
                    cartTotal.totalQuantity += quantity;
                    return cartTotal;
                }, {
                totalPrice: 0,
                totalQuantity: 0
            }
            );
            state.totalPrice = parseFloat(totalPrice.toFixed(2));
            state.totalQuantity = totalQuantity;
        },
        removeItem: (state, action) => {
            state.cart = state.cart.filter((item) => item.id !== action.payload)
            state.itemId = state.itemId.filter((item)=>item !== action.payload)//for remove ProductCart.js page button color
        },
        increaseItemQuantity: (state, action) => {
            state.cart.map((item) => {
                if (item.id == action.payload) {
                    // console.log(item.quantity);
                    item.quantity++
                }
            });
            // console.log(ids);
        },
        decreaseItemQuantity: (state, action) => {
            state.cart.map((item) => {
                if (item.id == action.payload) {
                    if (item.quantity == 1) {
                        alert('Quantity should not less than 1'); return false;
                    }
                    item.quantity--
                }
            })
        }
    }
})
export default cartSlice.reducer
export const { addToCart, getCartTotal, removeItem, increaseItemQuantity, decreaseItemQuantity } = cartSlice.actions