import React from 'react'

function Benefits() {
	return (
		<>
		<div className='container'><br />
			<div className='row'>
				<h1 className='text-center'>Online Shopping Benefits</h1><br />
				<p>Here's a list of benefits of online shopping:
					<br /><br />
					1. <b>Convenience:</b> Online shopping allows you to shop from the comfort of your home, office, or anywhere with internet access. There's no need to travel to physical stores, saving time and effort.
					<br /><br />
					2. <b>24/7 Availability:</b> Online stores are open 24 hours a day, 7 days a week, allowing you to shop at any time that fits your schedule.
					<br /><br />
					3. <b>Wide Selection:</b> Online shopping provides access to a vast array of products from various brands and retailers. You can easily compare prices and find unique items that might not be available locally.
					<br /><br />
					4. <b>Price Comparisons:</b> With online shopping, you can quickly compare prices from different vendors, helping you find the best deals and save money.
					<br /><br />
					5. <b>Easy Search and Filtering:</b> Online stores offer search and filtering options, making it simple to find specific products based on categories, brands, prices, and other criteria.
					<br /><br />
					6. <b>Reviews and Ratings:</b> Customers can read product reviews and ratings from other buyers, which can aid in making informed purchasing decisions.
					<br /><br />
					7. <b>Discounts and Deals:</b> Online retailers often offer exclusive discounts, promotions, and deals that can lead to significant savings.
					<br /><br />
					8. <b>No Crowds:</b> Avoiding crowded stores is a significant advantage, especially during busy shopping seasons or holidays.
					<br /><br />
					9. <b>No Geographic Limitations:</b> You can shop from online stores located anywhere in the world, expanding your access to unique products and international brands.
					<br /><br />
					10. <b>Easy Payment Options:</b> Online shopping offers various payment methods, including credit/debit cards, digital wallets, and online banking, providing convenience and security.
					<br /><br />
					11. <b>Home Delivery:</b> Most online purchases can be delivered to your doorstep, saving you the hassle of carrying heavy bags or packages.
					<br /><br />
					12. <b>Privacy and Discretion:</b> Online shopping allows you to shop for personal or sensitive items without feeling self-conscious or embarrassed.
					<br /><br />
					13. <b>Easy Returns and Refunds:</b> Reputable online retailers often have straightforward return policies, making it convenient to return or exchange products if needed.
					<br /><br />
					14. <b>Environmental Impact:</b> Online shopping can reduce the carbon footprint associated with transportation to physical stores, especially if you choose eco-friendly shipping options.
					<br /><br />
					15. <b>Access to Expert Advice:</b> Some online stores offer customer support, providing expert advice and assistance with product inquiries.
					<br /><br />
					Overall, online shopping offers convenience, a wide selection, and the potential for cost savings, making it a popular choice for consumers worldwide.</p>
			</div>
		</div>
		</>
	)
}

export default Benefits