import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';

export default function Carousel() {
  return (
    <><br/><br/><br/>
      <MDBCarousel showControls showIndicators>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={1}
          src={'images/carousel1.jpg'}
          alt='...'
        >
          <h1 style={{ position: 'absolute', bottom: '300px', left: '0', right: '10', fontSize:'70px'}}>E-Mart</h1>
          <h1 style={{ position: 'absolute', bottom: '250px', left: '0', right: '10' }}>Online Shopping Store</h1>
          <p style={{ position: 'absolute', bottom: '200px', left: '0', right: '50' }}>Shop your dream products by sitting at your home</p>
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={2}
          src='images/carousel2.jpg'
          alt='...'
        >
          <h1 style={{ position: 'absolute', bottom: '300px', left: '0', right: '0', fontSize:'70px'}}>E-Mart</h1>
          <h1 style={{ position: 'absolute', bottom: '205px', left: '0', right: '0' }}>Online Shopping Store</h1>
          <p style={{ position: 'absolute', bottom: '150px', left: '0', right: '0' }}>Shop your dream products by sitting at your home</p>
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={3}
          src='images/carousel3.jpg'
          alt='...'
        >
          {/* <h1 style={{ position: 'absolute', bottom: '205px', left: '0', right: '0' }}>Online Shopping Store</h1>
          <p style={{ position: 'absolute', bottom: '150px', left: '0', right: '0' }}>Shop your dream products by sitting at your home</p> */}
        </MDBCarouselItem>
      </MDBCarousel>
    </>
  );
}