import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import ProductCart from './components/ProductCart';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import CartPage from './components/CartPage';
import About from './About';
import Benefits from './Benefits';
import Footer from './Footer';
import ProductDetails from './components/ProductDetails';

function App() {
  return (
    <>
      {/* <Navbar /> */}


      {/* <BrowserRouter> */}
        <Navbar />

        <Routes>
          <Route path='/' element={<ProductCart />}></Route>
          <Route path='/cart' element={<CartPage />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/benefits' element={<Benefits />}></Route>
          <Route path='/productDetails/:id' element={<ProductDetails />}></Route>
        </Routes>
      <Footer/>
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
